import type { VideoTypes } from '~/types/VideoTypes';

const tencentIdentifier = [
  'web.sdk.qcloud.com',
  'vod2.myqcloud.com',
  'vod-qcloud.com'
];
const youtubeIdentifier = ['youtu.be', 'youtube.com'];

export function videoType(url: string | undefined): VideoTypes {
  let videoType: VideoTypes = 'video';

  if (tencentIdentifier.some((ti) => url?.includes(ti))) {
    videoType = 'iframe';
  } else {
    youtubeIdentifier.forEach((value) => {
      if (url?.includes(value)) {
        videoType = 'youtube';
      }
    });
  }
  return videoType;
}
