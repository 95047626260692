import type { VideoFormat } from '~/types/VideoFormat';

export function videoFormatCalculator(url: string | undefined): VideoFormat {
  let format: VideoFormat = 'none';
  if (url?.includes('mp4')) {
    format = 'mp4';
  } else if (url?.includes('webm')) {
    format = 'webm';
  }
  return format;
}
