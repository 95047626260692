import { videoType } from './videoType';
import { youtubeId } from '~/utils/youtubeId';
import type { IVideoFields } from '~/lib/ContentfulService';

export function videoUrlCalculator(
  videoFields: IVideoFields,
  isFallback = false
) {
  const type = videoType(videoFields?.externalVideoUrl);
  if (type === 'iframe') {
    return videoFields?.externalVideoUrl
      ? `${videoFields?.externalVideoUrl}&autoplay=${videoFields.autoplay}`
      : null;
  }

  if (type === 'youtube') {
    const youtubeUrl = youtubeId(videoFields?.externalVideoUrl ?? '');
    return videoFields?.externalVideoUrl
      ? `https://www.youtube.com/embed/${youtubeUrl}`
      : null;
  }

  return isFallback
    ? videoFields.localVideoAssetFallback?.fields?.file?.url
    : videoFields.localVideoAsset?.fields?.file?.url ?? null;
}
